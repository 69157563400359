import React from 'react'
import PropTypes from 'prop-types'

import { graphql, Link } from 'gatsby'
import Image from 'components/Image'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'

import Lead from 'components/Lead'
import Layout from 'components/Layout'
import NotFoundPage from 'pages/404'

const IS_BROWSER = typeof window !== 'undefined'

class ExperiencesIndexPage extends React.Component {
  render() {
    const { location, data: staticData, pageContext } = this.props
    const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
    const data = mergePrismicPreviewData({ staticData, previewData })

    const experiences = data.experiences.nodes
    const { numPages } = pageContext
    const uid = data.prismicExperiencesIndex.uid
    const experiencesIndexPage = data.prismicExperiencesIndex.data

    if (experiencesIndexPage == null)
      return <NotFoundPage location={location} />

    const {
      breadcrumb: { crumbs },
    } = pageContext

    return (
      <Layout
        heroImage={experiencesIndexPage.hero}
        crumbs={crumbs}
        metaTitle={experiencesIndexPage.meta_title}
        metaDescription={experiencesIndexPage.meta_description}
        pageTitle={experiencesIndexPage.title}
        location={location}
      >
        <Lead
          leadHeading={experiencesIndexPage.heading}
          leadText={experiencesIndexPage.content}
        />

        {experiences.length && (
          <div className="bg-cream py-7 md:py-20">
            <div className="container">
              <div className="xl:px-8">
                <div className="flex flex-wrap -ml-5 -mb-5 xl:-ml-8 xl:-mb-8">
                  {experiences.map(
                    (experience, index) =>
                      experience.data && (
                        <div
                          className="w-full sm:w-1/2 pl-5 pb-5 xl:pl-8 xl:pb-8"
                          key={index}
                        >
                          <div className="h-full bg-white shadow-md">
                            {(experience.data.summary_image.localFile ||
                              experience.data.summary_image.url) && (
                              <div className="ratio ratio--3/5">
                                <div className="absolute inset-0">
                                  <Image
                                    className="w-full h-full"
                                    src={experience.data.summary_image.url}
                                    fluid={
                                      experience.data.summary_image.localFile
                                        .childImageSharp.fluid
                                    }
                                    alt={experience.data.summary_image.alt}
                                  />
                                </div>
                              </div>
                            )}
                            <div className="sm:max-w-xl mx-auto pt-6 lg:pt-16 xl:pt-18 px-5 xl:px-8">
                              <div className="flex flex-wrap -ml-5 xl:-ml-8">
                                {experience.data.title && (
                                  <div className="w-full lg:w-2/5 pl-5 xl:pl-8">
                                    <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold leading-snug mb-6 lg:mb-10">
                                      {experience.data.title}
                                    </h3>
                                    <div className="dash mb-6 lg:mb-0"></div>
                                  </div>
                                )}
                                <div className="w-full lg:w-3/5 pl-5 xl:pl-8">
                                  {experience.data.summary_text && (
                                    <p className="text-xs sm:text-base mb-6 lg:mb-12">
                                      {experience.data.summary_text}
                                    </p>
                                  )}
                                  {experience.uid && (
                                    <Link
                                      to={`/${uid}/${experience.uid}/`}
                                      className="button-primary"
                                    >
                                      More
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
              {numPages > 1 && (
                <div className="flex items-center justify-center pt-7 md:pt-14">
                  {Array.from({ length: numPages }, (_, i) => (
                    <Link
                      key={`pagination-number${i + 1}`}
                      activeClassName="text-blue underline"
                      className="md:text-xl mx-2"
                      to={`/experiences/${i === 0 ? '' : i + 1}/`}
                    >
                      {i + 1}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

ExperiencesIndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const pageExperienceIndexQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    prismicExperiencesIndex {
      uid
      data {
        content {
          html
        }
        heading
        meta_description
        meta_title
        title
        hero {
          alt
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    experiences: allPrismicExperiences(
      limit: $limit
      skip: $skip
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        id
        uid
        data {
          summary_text
          title
          summary_image {
            localFile {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ExperiencesIndexPage
